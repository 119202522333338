import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/usr/src/nuxt-app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_F8atB0kBNE8FIgT_ajBXU_q0VzJ7X_J9WFqahA8C37U from "/usr/src/nuxt-app/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc from "/usr/src/nuxt-app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_A_mOOaCOuJWV5Ht8_pCnoQk4Wr7Lby7kkLEtrwvUQ5w from "/usr/src/nuxt-app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import api_V7vXtjocYIgrYhTpn78AOlqodlI3uYkEnKOo8M83mEs from "/usr/src/nuxt-app/src/plugins/api.ts";
import fontawesome_PhDYKScvhD9vuK4NrUE_o9xIMP79DebxTp7lochwkQ0 from "/usr/src/nuxt-app/src/plugins/fontawesome.js";
import gsap_client_UDf7VCt4PvRRC7vMNL4mvTUIy26YB5XXT3rnYuG8mmo from "/usr/src/nuxt-app/src/plugins/gsap.client.js";
import gtm_client_2efSqQyxUEfCV1tBHyTm5Z9wXkZfUENOAtlUFC3ZBqo from "/usr/src/nuxt-app/src/plugins/gtm.client.js";
import rdstation_client_1ZgKMrdz3X1DgbfDBK50o5XUf7Jp_OTGogOjkO_Mn3c from "/usr/src/nuxt-app/src/plugins/rdstation.client.js";
import vue_autocounter_WEIr8pgwPtdrnSjWNaywmo_qZj2FWpdxC7wxHdY7_mM from "/usr/src/nuxt-app/src/plugins/vue-autocounter.js";
import vue_scrollto_pSJnHIpTuPB5GxO3wqwpo3JI_rKn7FX4jbu6M6Z1Lk4 from "/usr/src/nuxt-app/src/plugins/vue-scrollto.ts";
import vueAwesomePaginate_lY76nPUeBEkYuOpQB6QIjgVax7fXZwU22Jc2NnGTIbA from "/usr/src/nuxt-app/src/plugins/vueAwesomePaginate.ts";
import vuetify_g1sySFeXB6iJb1JMj1Dvdeb_ajJP9zZWO2WhtouFH9g from "/usr/src/nuxt-app/src/plugins/vuetify.js";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  motion_F8atB0kBNE8FIgT_ajBXU_q0VzJ7X_J9WFqahA8C37U,
  nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc,
  plugin_A_mOOaCOuJWV5Ht8_pCnoQk4Wr7Lby7kkLEtrwvUQ5w,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  api_V7vXtjocYIgrYhTpn78AOlqodlI3uYkEnKOo8M83mEs,
  fontawesome_PhDYKScvhD9vuK4NrUE_o9xIMP79DebxTp7lochwkQ0,
  gsap_client_UDf7VCt4PvRRC7vMNL4mvTUIy26YB5XXT3rnYuG8mmo,
  gtm_client_2efSqQyxUEfCV1tBHyTm5Z9wXkZfUENOAtlUFC3ZBqo,
  rdstation_client_1ZgKMrdz3X1DgbfDBK50o5XUf7Jp_OTGogOjkO_Mn3c,
  vue_autocounter_WEIr8pgwPtdrnSjWNaywmo_qZj2FWpdxC7wxHdY7_mM,
  vue_scrollto_pSJnHIpTuPB5GxO3wqwpo3JI_rKn7FX4jbu6M6Z1Lk4,
  vueAwesomePaginate_lY76nPUeBEkYuOpQB6QIjgVax7fXZwU22Jc2NnGTIbA,
  vuetify_g1sySFeXB6iJb1JMj1Dvdeb_ajJP9zZWO2WhtouFH9g,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]