
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91title_938zneih7J7Y_RCGmx751DXunP5Xp_7w0Wyup1w2x5Z50Meta } from "/usr/src/nuxt-app/src/pages/blog/[title].vue?macro=true";
import { default as indexMYtjEWoPqZ7BIUdMKuG_Wsp4koYOPS00K82q8GprszwMeta } from "/usr/src/nuxt-app/src/pages/blog/index.vue?macro=true";
import { default as indexwREjIHNCm8tder0z_Du9N77TBviaBtggy_45gYwLvg9loMeta } from "/usr/src/nuxt-app/src/pages/blog/todos/index.vue?macro=true";
import { default as indexUiZu7KDAtTBzbVyk_45BjW_45QmRXy6hQLb26tIulJJbTTsMeta } from "/usr/src/nuxt-app/src/pages/carreiras/index.vue?macro=true";
import { default as backofficeQypWGwa4WdrWJCb9_i1GRBW4ms31k7Q4emscHCHxm44Meta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue?macro=true";
import { default as custodia_45internacionalMOu0_45xglcapsgsX7hhIGJh_45V7c655kBbCxOkXokuWEAMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue?macro=true";
import { default as experiencia_45varejoWVkG5EfWAxi2GPU6VL_45pkl048mfpSY_45jxBmB2v6Z3yQMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue?macro=true";
import { default as faturamento_45digitalmVz2riD7QGfE8QMA_q6OewsBv6RH2OvgS_h0rJkHVqwMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue?macro=true";
import { default as indexOanhizsyuOEzZ3tbcQP_I_3pb1Ouztfuxbb5gddzfY0Meta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue?macro=true";
import { default as painel_45gerencialQQO9FCKYDYgjdbaYpSlCrEwS0J21kPBzC7E7eXqKRsYMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue?macro=true";
import { default as rpaTFjQKCzSP4UbekyQAjAMb3hPn5cXoIum67n9YP4V5kQMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue?macro=true";
import { default as salesforceZR3OV6Wo25wLxaiZHqRh6088kRpFX7ofsVBoydCXsBUMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue?macro=true";
import { default as sapCR54ShQDoqNDCm97iuViTNXCfKO3WAGl6lseMq7_TngMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue?macro=true";
import { default as solucao_45integradabTUoC_sdn0O7vp6TtVWNtO2j4RU2z3kkw8mgjCvtRAAMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue?macro=true";
import { default as vida_45empresarialBGBXkB6GIL_ZK9rj7tqjyTZzsFhW_2dp3FPcCcvhQkYMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue?macro=true";
import { default as index7_45IVsh5wsspzAYxWmmGV2lqrvLHuq1DdLEYipl5VgDEMeta } from "/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue?macro=true";
import { default as indexw3zxWsJG_450bVUhlFd71KmoaAg7ib4hpWxcan_tBPCdIMeta } from "/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue?macro=true";
import { default as indexNTHvhhhOwgoz6HgFSExSvOLHPgcHJtCzH_anZD_t6v0Meta } from "/usr/src/nuxt-app/src/pages/contato/index.vue?macro=true";
import { default as indexnqelDf0yUZUonTa_4552PXCtW3Nik_45ASQ6NM_onhlRnOoMeta } from "/usr/src/nuxt-app/src/pages/devops/index.vue?macro=true";
import { default as index98TE3L3ojOU51TauahAPgUARXPfAZfsBqxDXc_pGoYkMeta } from "/usr/src/nuxt-app/src/pages/hunting/index.vue?macro=true";
import { default as index61AIoQhDPP5UjaZmnje8qjAsIE4jY_45epJqg9a8iyvCUMeta } from "/usr/src/nuxt-app/src/pages/index.vue?macro=true";
import { default as indexOl4UK7Z3640K_AAaOxM2Asgg1ShZRkjLKk_Z0Pt1PgoMeta } from "/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue?macro=true";
import { default as index3VmoMjooVZ5aTpEWmTFzjbLifgHpAZCdq6fb9vlPGNsMeta } from "/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue?macro=true";
import { default as indexKilZX3hVKhyCBAK2V5YdRIye75qsnufPDWQYyy3hpZEMeta } from "/usr/src/nuxt-app/src/pages/manifesto/index.vue?macro=true";
import { default as indexvzeKhYDE0tm93yuK2f1dvQBh6kRD71qd1IcVAJoJ7KYMeta } from "/usr/src/nuxt-app/src/pages/midia/index.vue?macro=true";
import { default as indexoLcyxglg04UDu4HK8ysClDnQBZHdC6C0qCtplVZ8lc8Meta } from "/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue?macro=true";
import { default as indexNG225beylm2rlO9Z8QcNONTsLw_60jkWKOm4E3U2egoMeta } from "/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue?macro=true";
import { default as indexEr_pmKLBY5LoYi2lRyIixeFnOP5yMXi2_45VZy4UxbegoMeta } from "/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue?macro=true";
import { default as indexlQv8RGkCMC9ueC8Hc9_45m7_45CPYitO00K1hhoxH8rjYMeta } from "/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue?macro=true";
import { default as index42_45Hk1cGbFjO3y_GRSX1ieoyoYLoOozUf_7JoadZRscMeta } from "/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexLGX5E5UNUkMrHuD7saLEcyMM_45EZvs0aAC0wc5ssChI0Meta } from "/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue?macro=true";
import { default as index8fRMWH5hH_45yVesffCRisRDb97smJ90DDrwUDBAw0mY0Meta } from "/usr/src/nuxt-app/src/pages/quem-somos/index.vue?macro=true";
import { default as index7Z3IZkS8PctBwbksVPLr2mAwFqUq_M54zbWQc3ATAfAMeta } from "/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue?macro=true";
export default [
  {
    name: "blog-title___pt",
    path: "/blog/:title()",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/[title].vue")
  },
  {
    name: "blog-title___en",
    path: "/en/blog/:title()",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/[title].vue")
  },
  {
    name: "blog-title___es",
    path: "/es/blog/:title()",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/[title].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/index.vue")
  },
  {
    name: "blog-todos___pt",
    path: "/blog/todos",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/todos/index.vue")
  },
  {
    name: "blog-todos___en",
    path: "/en/blog/todos",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/todos/index.vue")
  },
  {
    name: "blog-todos___es",
    path: "/es/blog/todos",
    component: () => import("/usr/src/nuxt-app/src/pages/blog/todos/index.vue")
  },
  {
    name: "carreiras___pt",
    path: "/carreiras",
    component: () => import("/usr/src/nuxt-app/src/pages/carreiras/index.vue")
  },
  {
    name: "carreiras___en",
    path: "/en/carreiras",
    component: () => import("/usr/src/nuxt-app/src/pages/carreiras/index.vue")
  },
  {
    name: "carreiras___es",
    path: "/es/carreiras",
    component: () => import("/usr/src/nuxt-app/src/pages/carreiras/index.vue")
  },
  {
    name: "casos-de-sucesso-backoffice___pt",
    path: "/casos-de-sucesso/backoffice",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue")
  },
  {
    name: "casos-de-sucesso-backoffice___en",
    path: "/en/casos-de-sucesso/backoffice",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue")
  },
  {
    name: "casos-de-sucesso-backoffice___es",
    path: "/es/casos-de-sucesso/backoffice",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue")
  },
  {
    name: "casos-de-sucesso-custodia-internacional___pt",
    path: "/casos-de-sucesso/custodia-internacional",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue")
  },
  {
    name: "casos-de-sucesso-custodia-internacional___en",
    path: "/en/casos-de-sucesso/custodia-internacional",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue")
  },
  {
    name: "casos-de-sucesso-custodia-internacional___es",
    path: "/es/casos-de-sucesso/custodia-internacional",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue")
  },
  {
    name: "casos-de-sucesso-experiencia-varejo___pt",
    path: "/casos-de-sucesso/experiencia-varejo",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue")
  },
  {
    name: "casos-de-sucesso-experiencia-varejo___en",
    path: "/en/casos-de-sucesso/experiencia-varejo",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue")
  },
  {
    name: "casos-de-sucesso-experiencia-varejo___es",
    path: "/es/casos-de-sucesso/experiencia-varejo",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue")
  },
  {
    name: "casos-de-sucesso-faturamento-digital___pt",
    path: "/casos-de-sucesso/faturamento-digital",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue")
  },
  {
    name: "casos-de-sucesso-faturamento-digital___en",
    path: "/en/casos-de-sucesso/faturamento-digital",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue")
  },
  {
    name: "casos-de-sucesso-faturamento-digital___es",
    path: "/es/casos-de-sucesso/faturamento-digital",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue")
  },
  {
    name: "casos-de-sucesso___pt",
    path: "/casos-de-sucesso",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue")
  },
  {
    name: "casos-de-sucesso___en",
    path: "/en/casos-de-sucesso",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue")
  },
  {
    name: "casos-de-sucesso___es",
    path: "/es/casos-de-sucesso",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue")
  },
  {
    name: "casos-de-sucesso-painel-gerencial___pt",
    path: "/casos-de-sucesso/painel-gerencial",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue")
  },
  {
    name: "casos-de-sucesso-painel-gerencial___en",
    path: "/en/casos-de-sucesso/painel-gerencial",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue")
  },
  {
    name: "casos-de-sucesso-painel-gerencial___es",
    path: "/es/casos-de-sucesso/painel-gerencial",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue")
  },
  {
    name: "casos-de-sucesso-rpa___pt",
    path: "/casos-de-sucesso/rpa",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue")
  },
  {
    name: "casos-de-sucesso-rpa___en",
    path: "/en/casos-de-sucesso/rpa",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue")
  },
  {
    name: "casos-de-sucesso-rpa___es",
    path: "/es/casos-de-sucesso/rpa",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue")
  },
  {
    name: "casos-de-sucesso-salesforce___pt",
    path: "/casos-de-sucesso/salesforce",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue")
  },
  {
    name: "casos-de-sucesso-salesforce___en",
    path: "/en/casos-de-sucesso/salesforce",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue")
  },
  {
    name: "casos-de-sucesso-salesforce___es",
    path: "/es/casos-de-sucesso/salesforce",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue")
  },
  {
    name: "casos-de-sucesso-sap___pt",
    path: "/casos-de-sucesso/sap",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue")
  },
  {
    name: "casos-de-sucesso-sap___en",
    path: "/en/casos-de-sucesso/sap",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue")
  },
  {
    name: "casos-de-sucesso-sap___es",
    path: "/es/casos-de-sucesso/sap",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue")
  },
  {
    name: "casos-de-sucesso-solucao-integrada___pt",
    path: "/casos-de-sucesso/solucao-integrada",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue")
  },
  {
    name: "casos-de-sucesso-solucao-integrada___en",
    path: "/en/casos-de-sucesso/solucao-integrada",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue")
  },
  {
    name: "casos-de-sucesso-solucao-integrada___es",
    path: "/es/casos-de-sucesso/solucao-integrada",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue")
  },
  {
    name: "casos-de-sucesso-vida-empresarial___pt",
    path: "/casos-de-sucesso/vida-empresarial",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue")
  },
  {
    name: "casos-de-sucesso-vida-empresarial___en",
    path: "/en/casos-de-sucesso/vida-empresarial",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue")
  },
  {
    name: "casos-de-sucesso-vida-empresarial___es",
    path: "/es/casos-de-sucesso/vida-empresarial",
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue")
  },
  {
    name: "clientes-e-parceiros___pt",
    path: "/clientes-e-parceiros",
    component: () => import("/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue")
  },
  {
    name: "clientes-e-parceiros___en",
    path: "/en/clientes-e-parceiros",
    component: () => import("/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue")
  },
  {
    name: "clientes-e-parceiros___es",
    path: "/es/clientes-e-parceiros",
    component: () => import("/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue")
  },
  {
    name: "codigo-de-etica-e-conduta___pt",
    path: "/codigo-de-etica-e-conduta",
    component: () => import("/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue")
  },
  {
    name: "codigo-de-etica-e-conduta___en",
    path: "/en/codigo-de-etica-e-conduta",
    component: () => import("/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue")
  },
  {
    name: "codigo-de-etica-e-conduta___es",
    path: "/es/codigo-de-etica-e-conduta",
    component: () => import("/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue")
  },
  {
    name: "contato___pt",
    path: "/contato",
    component: () => import("/usr/src/nuxt-app/src/pages/contato/index.vue")
  },
  {
    name: "contato___en",
    path: "/en/contato",
    component: () => import("/usr/src/nuxt-app/src/pages/contato/index.vue")
  },
  {
    name: "contato___es",
    path: "/es/contato",
    component: () => import("/usr/src/nuxt-app/src/pages/contato/index.vue")
  },
  {
    name: "devops___pt",
    path: "/devops",
    component: () => import("/usr/src/nuxt-app/src/pages/devops/index.vue")
  },
  {
    name: "devops___en",
    path: "/en/devops",
    component: () => import("/usr/src/nuxt-app/src/pages/devops/index.vue")
  },
  {
    name: "devops___es",
    path: "/es/devops",
    component: () => import("/usr/src/nuxt-app/src/pages/devops/index.vue")
  },
  {
    name: "hunting___pt",
    path: "/hunting",
    component: () => import("/usr/src/nuxt-app/src/pages/hunting/index.vue")
  },
  {
    name: "hunting___en",
    path: "/en/hunting",
    component: () => import("/usr/src/nuxt-app/src/pages/hunting/index.vue")
  },
  {
    name: "hunting___es",
    path: "/es/hunting",
    component: () => import("/usr/src/nuxt-app/src/pages/hunting/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    component: () => import("/usr/src/nuxt-app/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/nuxt-app/src/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/src/nuxt-app/src/pages/index.vue")
  },
  {
    name: "inteligencia-aplicada___pt",
    path: "/inteligencia-aplicada",
    component: () => import("/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue")
  },
  {
    name: "inteligencia-aplicada___en",
    path: "/en/inteligencia-aplicada",
    component: () => import("/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue")
  },
  {
    name: "inteligencia-aplicada___es",
    path: "/es/inteligencia-aplicada",
    component: () => import("/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue")
  },
  {
    name: "lp-bula-digital___pt",
    path: "/lp/bula-digital",
    meta: index3VmoMjooVZ5aTpEWmTFzjbLifgHpAZCdq6fb9vlPGNsMeta || {},
    component: () => import("/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue")
  },
  {
    name: "lp-bula-digital___en",
    path: "/en/lp/bula-digital",
    meta: index3VmoMjooVZ5aTpEWmTFzjbLifgHpAZCdq6fb9vlPGNsMeta || {},
    component: () => import("/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue")
  },
  {
    name: "lp-bula-digital___es",
    path: "/es/lp/bula-digital",
    meta: index3VmoMjooVZ5aTpEWmTFzjbLifgHpAZCdq6fb9vlPGNsMeta || {},
    component: () => import("/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue")
  },
  {
    name: "manifesto___pt",
    path: "/manifesto",
    component: () => import("/usr/src/nuxt-app/src/pages/manifesto/index.vue")
  },
  {
    name: "manifesto___en",
    path: "/en/manifesto",
    component: () => import("/usr/src/nuxt-app/src/pages/manifesto/index.vue")
  },
  {
    name: "manifesto___es",
    path: "/es/manifesto",
    component: () => import("/usr/src/nuxt-app/src/pages/manifesto/index.vue")
  },
  {
    name: "midia___pt",
    path: "/midia",
    component: () => import("/usr/src/nuxt-app/src/pages/midia/index.vue")
  },
  {
    name: "midia___en",
    path: "/en/midia",
    component: () => import("/usr/src/nuxt-app/src/pages/midia/index.vue")
  },
  {
    name: "midia___es",
    path: "/es/midia",
    component: () => import("/usr/src/nuxt-app/src/pages/midia/index.vue")
  },
  {
    name: "o-que-fazemos___pt",
    path: "/o-que-fazemos",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue")
  },
  {
    name: "o-que-fazemos___en",
    path: "/en/o-que-fazemos",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue")
  },
  {
    name: "o-que-fazemos___es",
    path: "/es/o-que-fazemos",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue")
  },
  {
    name: "o-que-fazemos-inteligencia-sap___pt",
    path: "/o-que-fazemos/inteligencia-sap",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue")
  },
  {
    name: "o-que-fazemos-inteligencia-sap___en",
    path: "/en/o-que-fazemos/inteligencia-sap",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue")
  },
  {
    name: "o-que-fazemos-inteligencia-sap___es",
    path: "/es/o-que-fazemos/inteligencia-sap",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue")
  },
  {
    name: "o-que-fazemos-rpa___pt",
    path: "/o-que-fazemos/rpa",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue")
  },
  {
    name: "o-que-fazemos-rpa___en",
    path: "/en/o-que-fazemos/rpa",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue")
  },
  {
    name: "o-que-fazemos-rpa___es",
    path: "/es/o-que-fazemos/rpa",
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue")
  },
  {
    name: "politica-de-cookies___pt",
    path: "/politica-de-cookies",
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue")
  },
  {
    name: "politica-de-cookies___en",
    path: "/en/politica-de-cookies",
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue")
  },
  {
    name: "politica-de-cookies___es",
    path: "/es/politica-de-cookies",
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue")
  },
  {
    name: "politica-de-privacidade___pt",
    path: "/politica-de-privacidade",
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue")
  },
  {
    name: "politica-de-privacidade___en",
    path: "/en/politica-de-privacidade",
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue")
  },
  {
    name: "politica-de-privacidade___es",
    path: "/es/politica-de-privacidade",
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue")
  },
  {
    name: "programa-de-formacao___pt",
    path: "/programa-de-formacao",
    component: () => import("/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue")
  },
  {
    name: "programa-de-formacao___en",
    path: "/en/programa-de-formacao",
    component: () => import("/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue")
  },
  {
    name: "programa-de-formacao___es",
    path: "/es/programa-de-formacao",
    component: () => import("/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue")
  },
  {
    name: "quem-somos___pt",
    path: "/quem-somos",
    component: () => import("/usr/src/nuxt-app/src/pages/quem-somos/index.vue")
  },
  {
    name: "quem-somos___en",
    path: "/en/quem-somos",
    component: () => import("/usr/src/nuxt-app/src/pages/quem-somos/index.vue")
  },
  {
    name: "quem-somos___es",
    path: "/es/quem-somos",
    component: () => import("/usr/src/nuxt-app/src/pages/quem-somos/index.vue")
  },
  {
    name: "termos-e-condicoes-de-uso___pt",
    path: "/termos-e-condicoes-de-uso",
    component: () => import("/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue")
  },
  {
    name: "termos-e-condicoes-de-uso___en",
    path: "/en/termos-e-condicoes-de-uso",
    component: () => import("/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue")
  },
  {
    name: "termos-e-condicoes-de-uso___es",
    path: "/es/termos-e-condicoes-de-uso",
    component: () => import("/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue")
  }
]