export const appHead = {"meta":[{"charset":"utf-16"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description"},{"http-equiv":"cache-control","content":"no-cache, no-store, must-revalidate"},{"http-equiv":"expires","content":"0"},{"http-equiv":"pragma","content":"no-cache"}],"link":[{"rel":"icon","href":"/favicon.ico"},{"href":"/fonts/Montserrat-Regular.woff2","rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"href":"/fonts/Montserrat-ExtraBold.woff2","rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"href":"/fonts/Montserrat-Black.woff2","rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"href":"/fonts/Montserrat-Thin.woff2","rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"href":"https://api-cmssite.sis-it.com","rel":"preconnect","crossorigin":"anonymous"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"pt-BR"},"charset":"utf-16","viewport":"width=500, initial-scale=1","title":"SIS Innov & Tech - A melhor estratégia para a sua empresa"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false