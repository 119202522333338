
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.locale('pt-br')



dayjs.updateLocale("pt-br")

export default dayjs
